import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { useIntl, injectIntl, Link } from "gatsby-plugin-intl";
import Img from "gatsby-image";
import ServicesComp from '../components/servicesComp';
import ServiceForm from "../components/serviceForm";

import styled from 'styled-components';

const H1 = styled.h1`
    font-size: 30px;
    margin-bottom:35px;
    margin-top:25px;
}`;

const H2 = styled.h2`
    font-size: 22px;
}`;

const H3 = styled.h3`
    font-size: 20px;
    margin-top: 20px;
    margin-bottom:8px;
}`;

const TextJustified = styled.div`
    text-align: justify;
}`;

const P = styled.p`
    margin-top: 5px;
    color: black;
    font-size: inherit;
}`;

const PA = styled.p`
    margin-top: 20px;
    font-size: inherit;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}`;

const LI = styled.li`
    margin-left: 20px;
    margin-bottom: 0px;
}`;

const ServiceBuyingProperty = ({ data }) =>  {
    const intl = useIntl();
    const [showContent, setShowContent] = useState(false);

    const myHeaderRef = React.createRef();
    
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, myHeaderRef.current.offsetTop);
        }, 100);
    }, [])

    return (
        <Layout>
            <SEO title={intl.formatMessage({ id: 'Service1Title' })} />

            <section>
                <ServicesComp intl={intl}></ServicesComp>

                <section className="page-section blog-page">
                    <div className="container">
                        <TextJustified className="row" ref={myHeaderRef}>
                            <H1><strong>{intl.formatMessage({ id: 'Service1Title' })}</strong></H1>
                            <P>{intl.formatMessage({ id: 'Service1p1' })}</P>
                            <P><strong>{intl.formatMessage({ id: 'Service1p2' })}</strong></P>
                            <P>{intl.formatMessage({ id: 'Service1p3' })}</P>
                            <P>{intl.formatMessage({ id: 'Service1p4p1' })} <strong>{intl.formatMessage({ id: 'Service1p4p2' })}</strong> {intl.formatMessage({ id: 'Service1p4p3' })}</P>
                            {intl.locale !== 'es' && 
                                <P>{intl.formatMessage({ id: 'Service1p5p1' })} <strong>{intl.formatMessage({ id: 'Service1p5p2' })}</strong> {intl.formatMessage({ id: 'Service1p5p3' })} <strong>{intl.formatMessage({ id: 'Service1p5p4' })}</strong> {intl.formatMessage({ id: 'Service1p5p5' })}</P>
                            }
                            <P>{intl.formatMessage({ id: 'Service1p6p1' })} <strong>{intl.formatMessage({ id: 'Service1p6p2' })}</strong>) {intl.formatMessage({ id: 'Service1p6p3' })}</P>
                        </TextJustified>

                        <TextJustified className="row">
                            <PA>
                                <Link to="/service-tax-administration#administration">
                                    {intl.formatMessage({ id: 'Service1Link' })}
                                </Link>
                            </PA>
                        </TextJustified>

                        <TextJustified className="row">
                            <PA onClick={() => setShowContent(!showContent)}>
                                {intl.formatMessage({ id: 'Service1ShowContent' })}
                            </PA>
                        </TextJustified>

                        {showContent &&
                            <div className="row">
                                <div className="col-lg-7">
                                    <TextJustified className="row">
                                        <H2>{intl.formatMessage({ id: 'Service1Section2p1' })}</H2>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service1Section2p2' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p2List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p2List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p2List3' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p2List4' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p2List5' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p2List6' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service1Section2p3' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p3List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p3List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p3List3' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p3List4' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p3List5' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p3List6' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p3List7' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p3List8' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p3List9' })}</LI>
                                        </ul>
                                    </TextJustified>
                                    <TextJustified className="row">
                                        <H3>{intl.formatMessage({ id: 'Service1Section2p4' })}</H3>
                                        <ul>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p4List1' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p4List2' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p4List3' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p4List4' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p4List5' })}</LI>
                                            <LI>{intl.formatMessage({ id: 'Service1Section2p4List6' })}</LI>
                                        </ul>
                                    </TextJustified>
                                </div>

                                <div className="col-lg-1"></div>
                                
                                <div className="col-lg-4">
                                    <ServiceForm intl={intl} />
                                </div>
                            </div>
                        }

                        <br/>

                        <TextJustified className="row">
                            <Img className="detailIcon" fixed={data.alertIcon.childImageSharp.fixed} /><P>{intl.formatMessage({ id: 'Service1footer' })}</P>
                        </TextJustified>
                    </div>
                </section>
            </section>

        </Layout>
    )
}

export default injectIntl(ServiceBuyingProperty)
export const query = graphql`
query {
    alertIcon: file(relativePath: { eq: "alert.png" }) {
        childImageSharp {
            fixed(width: 30) {
                ...GatsbyImageSharpFixed
            }
        }
    },
}
`